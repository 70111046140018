.audio-container {
  z-index: 98;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

#audio-visualiser {
  height: 35px;
  width: 45px;
  justify-content: center;
  align-items: center;
  margin: 5px;
  display: flex;
}

#audio-visualiser .audio-wire {
  height: 15px;
  z-index: 0;
  --animdur: .6s;
  --animn: animMUSIC;
  --animtf: cubic-bezier(.57, .21, .69, 1.25);
  --animic: infinite;
  --animdir: alternate;
  --animps: running;
  will-change: height;
  background-color: #ffe0dc;
  margin: 0 1.2px;
  padding: 0 .5px;
  animation-play-state: paused;
}

.bg-audio-wire {
  height: 60%;
  width: 70%;
  z-index: -1;
  -webkit-filter: drop-shadow(0 0 .5px #9b9b9b);
  filter: drop-shadow(0 0 .5px #9b9b9b);
  background-color: #0b0a0ade;
  border: .1px solid #cbcbcb;
  border-radius: .4px;
  position: absolute;
}

#audio-visualiser .audio-wire-1 {
  --animdel: 50ms;
}

#audio-visualiser .audio-wire-2 {
  --animdel: .15s;
}

#audio-visualiser .audio-wire-3 {
  --animdel: .3s;
}

#audio-visualiser .audio-wire-4 {
  --animdel: .45s;
}

#audio-visualiser .audio-wire-5 {
  --animdel: .3s;
}

#audio-visualiser .audio-wire-6 {
  --animdel: .15s;
}

#audio-visualiser .audio-wire-7 {
  --animdel: 50ms;
}

@keyframes animMUSIC {
  to {
    height: 30%;
  }

  from {
    height: 10%;
  }
}

[data-animation] {
  animation: var(--animn, none) var(--animdur, 0s) var(--animtf, linear) var(--animdel, 0s) var(--animic, infinite) var(--animdir, alternate) var(--animfm, none) var(--animps, running);
}

/*# sourceMappingURL=index.2cd7bc7a.css.map */
